import { render, staticRenderFns } from "./selection-record.vue?vue&type=template&id=38819174&scoped=true&"
import script from "./selection-record.vue?vue&type=script&lang=js&"
export * from "./selection-record.vue?vue&type=script&lang=js&"
import style0 from "./selection-record.vue?vue&type=style&index=0&id=38819174&prod&lang=less&"
import style1 from "./selection-record.vue?vue&type=style&index=1&id=38819174&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38819174",
  null
  
)

export default component.exports